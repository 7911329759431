<template>
  <div class="tab-container">
    <transition :name="transitionName">
      <keep-alive>
        <router-view class="router-view" />
      </keep-alive>
    </transition>
    <van-tabbar route placeholder safe-area-inset-bottom>
      <van-tabbar-item replace to="/combo-buy">
        <van-icon
          slot="icon"
          slot-scope="{ active }"
          :name="active ? 'diamond' : 'diamond-o'"
        />
        套餐购买
      </van-tabbar-item>
      <van-tabbar-item replace to="/my" icon="user-o">
        <van-icon
          slot="icon"
          slot-scope="{ active }"
          :name="active ? 'manager' : 'manager-o'"
        />
        我的购买
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
  export default {
    name: 'TabPage',
    data() {
      return {
        transitionName: 'slide-left',
      };
    },
    created() {
      this.$requiredLogin();
    },
    beforeRouteUpdate(to, from, next) {
      if (this.$router.isBack) {
        this.transitionName = 'slide-right';
      } else {
        this.transitionName = 'slide-left';
      }
      this.$router.isBack = false;
      next();
    },
  };
</script>

<style lang="scss" scoped>
  $translate-width: 20%;

  .tab-container {
    overflow: hidden;
  }

  .router-view {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.8s cubic-bezier(0.55, 0, 0.1, 1);
  }
  .slide-left-enter,
  .slide-right-leave-active {
    opacity: 0;
    -webkit-transform: translate($translate-width, 0);
    transform: translate($translate-width, 0);
  }
  .slide-left-leave-active,
  .slide-right-enter {
    opacity: 0;
    -webkit-transform: translate(-$translate-width, 0);
    transform: translate(-$translate-width, 0);
  }
</style>
